.table-react-com {
    width: 100%;
    border-collapse: collapse;
    margin-top: 20px;
  
    th, td {
      padding: 10px;
      border: 1px solid #ddd;
      text-align: left;
    }
  
    th {
      background-color: #f9fbff;
      color: black;
    }
  }
  

  
  .table-button {
    margin: 10px;
    padding: 10px;
    background-color: #007bff;
    color: white;
    border: none;
    cursor: pointer;
  }

  .table-tr {
    &:hover {
      background-color: #f4f4f4;
      color: black;
    }
  }
  
  .pagination {
    display: flex;
    justify-content: center;
    margin-top: 20px;
  
    button {
      margin: 0 5px;
      padding: 5px 10px;
      background-color: #007bff;
      color: white;
      border: none;
      cursor: pointer;
  
      &:disabled {
        background-color: #ccc;
        cursor: not-allowed;
      }
  
      &:hover:not(:disabled) {
        background-color: #0056b3;
      }
    }
  }

.table-select {
    background-color: #171717;
    position: relative;
    color: #fff;
    font-family: Poppins;
    font-size: 0.75rem;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    padding: 10px;
    margin: 5px;
    height: 3rem;
    :focus {
      box-shadow: none !important;
    }
    option {
      height: 10rem;
    }
}

.bookNow {
    background-color: #413df6;
    color: white;
    min-width: 6rem;
    font-size: 0.875rem;
    font-weight: 500;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.3rem;
    border: none;
    border-radius: 0.5rem;
    width: 10.5rem;
}
  
.table-search{
    &:hover{
        background-color: #f4f4f4;
        color: black;
    }
    &::placeholder {
        color: #f4f4f4;
        opacity: 1;
    }
}
